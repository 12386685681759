<template></template>

<script>
import { openMiniProgram } from '@/utils/nwechat.js'
// 该页面用于打开小程序
export default {
  data() {
    return {}
  },
  async mounted() {
    const { appid, path } = this.$route.query
    await openMiniProgram({ appid, path })
    wx.closeWindow()
  }
}
</script>

<style></style>
